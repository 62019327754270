.sider {
  background: #1F3247;
  min-height: 100vh;
  position: fixed;
  left: 0;
  top: 48px;
}

.menu {
  margin-top: 0;
  padding-top: 0;
}

.menu :global(.ant-menu) {
  padding: 0;
}
