/* Base styles */
body {
  font-family: Arial, sans-serif;
  margin: 0; /* Reset default margin */
}

.center {
  text-align: center;
}

th, td {
  padding: 5px; /* Reduce padding */
  font-size: 12px; /* Smaller font size for the table content */
}

h1, h4 {
  font-size: 14px; /* Make headers smaller */
  margin: 0; /* Remove default margin */
}

.add {
  text-align: center;
}

.size-page {
  max-width: 800px; /* Adjust for general layout */
  margin: 0 auto;   /* Center the invoice */
}

table {
  width: 80%; /* Make tables full width */
  border-collapse: collapse; /* Merge table borders */
}

.border {
  padding: 5px; /* Reduce padding to prevent overflow */
  text-align: left; /* Align text to the left */
  border-left: 1px solid black;
  border-right: 1px solid black;
}

thead, tbody {
  width: 100%;
  margin: 0; /* Remove extra margin inside the table */
}

tfoot {
  border: none; /* Remove footer border */
}

.right {
  text-align: right;
}

/* Print Styles */
@media print {
  body {
    margin: 5mm; /* Set body margins for print */
    padding: 0; /* Remove padding for print */
  }

  .sale-actions {
    display: none; /* Hide buttons when printing */
  }

  .contain {
    margin: 0; /* No margin for print */
    padding: 0; /* Remove padding for print */
  }

  /* Ensure the table does not exceed the width of the page */
  table {
    width: 80%; /* Ensure table takes full width on print */
    border-collapse: collapse; /* Merge table borders */
    page-break-inside: avoid; /* Prevent tables from being split across pages */
  }

  .border {
    border-left: 1px solid black;
    border-right: 1px solid black;
    padding: 5px; /* Keep padding low to prevent overflow */
  }
}

/* @page rule for A4 page setup */
@page {
  size: A4; /* Set page size to A4 */
  margin: 10mm; /* Set margins for A4 page */
}

/* General fix for border overflow */
@media print {
  html, body {
    margin: 0; /* Remove margins for HTML and body */
    padding: 0; /* Remove padding for HTML and body */
    width: 100%; /* Ensure full width for printing */
  }

  .size-page {
    max-width: 100%; /* Ensure content fills the page */
  }
}
