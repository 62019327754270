.header {
  background: #1F3247;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  position: fixed;
  width: 100%;
  z-index: 1000;
}

.headerTitle {
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.headerRight {
  display: flex;
  align-items: center;
  gap: 16px;
}

.dropdown {
  color: white;
  cursor: pointer;
}

.languageSelector {
  color: white;
  margin-left: 16px;
}
