General Styling
body {
  font-family: Arial, sans-serif;
}

.center {
  text-align: center;
}

.add {
  text-align: center;
}

#two {
  border-top: none !important;
  border-bottom: none !important;
}

.invoice-page {
  max-width: 100%; /* Adjust for general layout */
  margin: 0 auto;   /* Center the invoice */
}

table {
  width: 100%;              /* Make tables full width */
  border-collapse: collapse; /* Collapse borders */
}

 .border {
  padding: 10px;            /* Add padding for better readability */
  text-align: left;         /* Align text to the left */
  border-left: 1px solid black !important;
  border-right: 1px solid black !important;
} 

thead, tbody {
  width: 100%; /* Ensure thead and tbody take full width */
}

h1, h4 {
  margin: 0;       /* Remove default margin */
  font-size: 14px; /* Make headers smaller */
}

tfoot {
  border: none; /* Remove footer border */
}

.right {
  text-align: right;
}

/* For Print Optimization */
@media print {
  .invoice-actions {
    display: none; /* Hide print/download buttons during print */
  }

  .hide-on-pdf {
    display: none !important; /* Hide any additional elements */
  }

  /* Hide headers and footers manually if browser allows */
  header, footer {
    display: none;
  }

  .border {
    padding: 10px;            /* Add padding for better readability */
    text-align: left;         /* Align text to the left */
    border-left: 1px solid black !important;
    border-right: 1px solid black !important;
  } 

  .invoice-page {
    width: 100%;
    max-width: 100%;
    margin: 0;           /* Remove margin for print */
    padding: 0;          /* Remove padding for print */
  }

  table {
    width: 100%;         /* Make table full width for print */
    border-collapse: collapse;
  }

 

  /* Adjustments for header/footer */
  h1, h4 {
    font-size: 10px;     /* Smaller header sizes for print */
  }

  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    margin-top: 10px;
    font-size: 8px;      /* Adjust footer font size */
  }
}

@media print {
  @page {
    size: A4;
    margin: 20mm; /* Set margins if needed */
  }
  .border {
    padding: 10px;            /* Add padding for better readability */
    text-align: left;         /* Align text to the left */
    border-left: 1px solid black !important;
    border-right: 1px solid black !important;
  } 
}